import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Guid } from 'guid-typescript';
import { Voucher } from '../../models/vouchers.model';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CatalogHttp {
  constructor(private api: ApiService) {}

  // set Management
  getSets(data): Observable<any> {
    return this.api.get('api/set/sets', data, 'cmsURL');
  }

  getSetSectionName(setId): Observable<any> {
    return this.api.get('api/set/setname', { setId }, 'cmsURL', 'text/html');
  }

  getSetShortData(setId): Observable<any> {
    return this.api.get('api/set/short-data', { setId }, 'cmsURL');
  }

  getSetDetails(data): Observable<any> {
    return this.api.get('api/set/setdetails', data, 'cmsURL');
  }

  importProducts(id, data): Observable<any> {
    return this.api.upload(`api/set/addmultiplesetproducts/${id}`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  deleteSetProducts(data): Observable<any> {
    return this.api.post(`api/set/deletesetproduct`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  deleteSet(id): Observable<any> {
    return this.api.delete(`api/set/products`, { setId: id, requestId: this.getGuidId() }, 'cmsURL');
  }

  // gio amis saxelebi mere sworad dawere :D
  deleteProduct(id): Observable<any> {
    return this.api.delete(`api/set`, { ...id, requestId: this.getGuidId() }, 'cmsURL');
  }

  getSetProducts(data): Observable<any> {
    return this.api.get('api/set/setproducts', data, 'cmsURL');
  }

  changeSetStatus(data): Observable<any> {
    let gUID = this.getGuidId();
    data.correlationId = gUID;
    return this.api.put('api/set/changesetstatus', data, { requestId: gUID }, 'cmsURL');
  }

  updateSet(data): Observable<any> {
    let gUID = this.getGuidId();
    data.correlationId = gUID;
    return this.api.put('api/set/updateset', data, { requestId: gUID }, 'cmsURL');
  }

  createSet(data): Observable<any> {
    let gUID = this.getGuidId();
    data.correlationId = gUID;
    return this.api.post(`api/set/createset`, data, { requestId: gUID }, 'cmsURL');
  }

  getReport(data): Observable<any> {
    return this.api.get('api/reports/products', data, 'cmsURL', 'blob');
  }

  getProductPriceHistory(data): Observable<any> {
    return this.api.get('api/products/productpricehistories', data, 'cmsURL');
  }

  getProductStatusHistory(data): Observable<any> {
    return this.api.get('api/products/product/status/history', data, 'cmsURL');
  }

  getModels(params: any) {
    return this.api.get('api/model', params, 'cmsURL');
  }

  getAdvertisements(data): Observable<any> {
    return this.api.get(
      `api/advertisements`,
      {
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
        searchValue: data.getGlobalFilter && data.getGlobalFilter,
        requestId: this.getGuidId()
      },
      'cmsURL'
    );
  }

  uploadAdvertisementsImage(data): Observable<any> {
    return this.api.upload(`api/upload/advertisementimage`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCategoryBanners(params): Observable<any> {
    return this.api.get(`api/brand-logos/by-category`, params, 'cmsURL');
  }

  getSearchTags(params): Observable<any> {
    return this.api.get(`api/searchqueries/getsearchqueries`, params, 'cmsURL');
  }

  updateAdvertisements(data): Observable<any> {
    return this.api.put(`api/advertisements`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  changeFeatureSuggestedValue(data): Observable<any> {
    return this.api.put(
      `api/featuresuggestedvalues/changefeaturesuggestedvalue`,
      data,
      { requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  addAdvertisements(data): Observable<any> {
    return this.api.post(`api/advertisements`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  deleteAdvertisements(data): Observable<any> {
    return this.api.delete(`api/advertisements`, { id: data, requestId: this.getGuidId() }, 'cmsURL');
  }

  getAdvertisementsById(id): Observable<any> {
    return this.api.get(`api/advertisements/${id}`, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getAdvertisementsSelectedCategory(id): Observable<any> {
    return this.api.get(`api/advertisements/${id}/categories`, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getInvalidStatement(params): Observable<any> {
    return this.api.get(`api/products/getinvalidstatement`, params, 'cmsURL');
  }

  getInvalidDetails(id): Observable<any> {
    return this.api.get(`api/products/${id}/details`, null, 'cmsURL');
  }

  deleteBlackList(data): Observable<any> {
    return this.api.delete('api/products/deletec2cinvalidstatement', data, 'cmsURL');
  }

  publishFromBlackList(data): Observable<any> {
    return this.api.post('api/products/publishc2cinvalidstatement', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  updateAdvertisementsSelectedCategory(data): Observable<any> {
    return this.api.post(`api/advertisements/update-categories`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCatalogSidebarFilter(data): Observable<any> {
    return this.api.post(`api/categories/sidebar-filter`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCatalogCategoriesBySetId(setId: number, darkStoreData): Observable<any> {
    return this.api.get(
      `api/set/${setId}/categories`,
      { darkStoreId: darkStoreData?.darkStoreId || darkStoreData?.defaultDarkStoreId },
      'cmsURL'
    );
  }

  getCatalogSidebarFilterMercury(data): Observable<any> {
    return this.api.post(`filters/beat-it`, data, {}, 'mercury-api-url');
  }

  // replacing getCatalogFilterProducts
  getMercuryBillieJean(data) {
    if (!data.sortBy) {
      data.sortBy = 1;
    }
    if (!data.sortType) {
      data.sortType = 1;
    }
    return this.api.post('search/billie-jean', data, {}, 'mercury-api-url');
  }

  getMerchantsMammaMia(data) {
    return this.api.post('search/mamma-mia', data, {}, 'mercury-api-url');
  }

  getMerchantParents(id) {
    return this.api.get(`api/categories/${id}/parents`, {}, 'cmsURL');
  }

  getMerchantParent(id) {
    return this.api.get(`api/categories/${id}/parent`, {}, 'cmsURL');
  }

  getMerchantChildren(id) {
    return this.api.get(`categories/${id}`, {}, 'mercury-api-url');
  }

  getBreadcrumbs(id): Observable<any> {
    return this.api.get(`api/categories/${id}/breadcrumbs`, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCatalogFilterModels(data): Observable<any> {
    return this.api.post(`api/categories/brands/models-for-sidebar`, data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCatalogFilterProducts(data): Observable<any> {
    return this.api.post('api/products/getfilteredproducts', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCatalogFilterProductsMerchans(data): Observable<any> {
    return this.api.post('api/products/getfilteredproducts/merchants', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCatalogRandomProducts(slug: string, setId: number, query: any): Observable<any> {
    return this.api.get(`api/set/catalog/set/${slug}/${setId}`, { requestId: this.getGuidId(), ...query }, 'cmsURL');
  }

  getProductBundles(id) {
    return this.api.get('api/products/product-bundles', { productId: id }, 'cmsURL');
  }

  getLandingProducts(data) {
    return this.api.get(
      `api/landingpages/by-slug/${data.landingSlug}`,
      { requestId: this.getGuidId() },
      'marketingURL'
    );
  }

  getApiProductsSaveSearch(data): Observable<any> {
    return this.api.post('api/products/save-search', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getCampaignInformation(params): Observable<any> {
    return this.api.get(
      'api/campaign/getcampaigninformation',
      { requestId: this.getGuidId(), ...params },
      'orderingURL'
    );
  }

  uploadSetImage(data): Observable<any> {
    return this.api.upload('api/upload/setimage', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  deleteSetImage(data): Observable<any> {
    return this.api.delete(
      `api/upload/set/image`,
      {
        requestId: this.getGuidId(),
        WebFileName: data?.WebFileName ? data?.WebFileName : null,
        MobileFileName: data?.MobileFileName ? data?.MobileFileName : null,
        SetId: data.SetId
      },
      'cmsURL'
    );
  }

  getServerTime(): Observable<any> {
    return this.api.post('api/date/now/utc', {}, {}, 'cmsURL');
  }

  getComparisonProducts() {
    return this.api.get('api/productscomparison/product/ids', { requestId: this.getGuidId() }, 'cmsURL');
  }

  addUserComparisonProductSingle(id) {
    return this.api.post('api/productscomparison', { productId: id }, { requestId: this.getGuidId() }, 'cmsURL');
  }

  getAnoymusUserComparisonProducts(data) {
    return this.api.get('api/productscomparison/product/published/ids', { ...data }, 'cmsURL');
  }

  addUserComparisonProductsMultiple(data) {
    return this.api.post('api/productscomparison/multiple', data, { requestId: this.getGuidId() }, 'cmsURL');
  }

  clearComparison() {
    return this.api.delete('api/productscomparison/clear', {}, 'cmsURL');
  }

  deleteComparisonProduct(id) {
    return this.api.delete('api/productscomparison', { productId: id, requestId: this.getGuidId() }, 'cmsURL');
  }

  getComparisonFeatures(data) {
    return this.api.get('api/productscomparison/product/features', { ...data }, 'cmsURL');
  }

  getExpressDarkStoreId(location): Observable<any> {
    return this.api.get(
      `api/express/darkstore`,
      {
        Latitude: location?.latitude,
        Longitude: location?.longitude
      },
      'cmsURL'
    );
  }

  getMerchantEndCategories(merchantId: string, searchValue: string): Observable<{ id: number; caption: string }> {
    return this.api.get(
      'api/categories/merchant/last-node',
      { merchantId, searchValue, requestId: this.getGuidId() },
      'cmsURL'
    );
  }

  findExpressProductByDarkstore(productId: number, darkStoreId: number): Observable<number> {
    return this.api.get<number>(`api/products/express-product/${productId}/in-darkstore`, { darkStoreId }, 'cmsURL');
  }

  getVouchers(): Observable<Voucher[]> {
    return this.api.get<Voucher[]>('api/products/vouchers', {}, 'cmsURL');
  }

  getMerchantRangesCategories(merchantUserId: string, searchValue: string): Observable<any> {
    return this.api.get('api/merchantmargin', { merchantUserId, searchValue, requestId: this.getGuidId() }, 'cmsURL');
  }

  addMerchantRangesCategories(data): Observable<HttpResponse<any>> {
    return this.api.post('api/merchantmargin', data, { requestId: this.getGuidId(), observe: 'response' }, 'cmsURL');
  }

  importCatalogRanges(merchantUserId: string, data): Observable<any> {
    return this.api.upload(
      'api/merchantmargin/import',
      data,
      {
        merchantUserId: merchantUserId,
        requestId: this.getGuidId()
      },
      'cmsURL',
      'blob'
    );
  }

  private getGuidId(): string {
    return Guid.create().toString();
  }
}
