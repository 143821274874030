<footer class="_x_relative _x_bg-dark">
  <!-- footer wrap /. -->
  <div class="_x_max-w-780 _x_w-full _x_px-8 _x_m-auto _x_pt-10 md:_x_pt-15 _x_pb-10 sm:_x_pb-25">
    <div class="_x_bg-[#7A1DFF] _x_w-full _x_h-2 _x_rounded-14">
    </div>
    <div class="_x_grid _x_grid-cols-12 _x_gap-0 _x_mt-10 md:_x_mt-15">
      <div class="_x_col-span-12 md:_x_col-span-3 _x_flex _x_justify-between _x_items-center md:_x_justify-between md:_x_block _x_pl-0      _x_pb-10 md:_x_pb-0">
        <a routerLink="/"
          class=" _x_max-w-[110px] _x_w-full md:_x_max-w-[148px] sm:_x_mr-15 md:_x_mr-0 _x_flex _x_items-center _x_justify-center sm:_x_justify-start">
          <img [src]="'../assets/atomic-assets/img/logo/new-logo-white.svg'" alt="">
        </a>
        <p class="md:_x_mt-10 _x_font-medium _x_text-2 sm:_x_text-3 _x_ml-10 md:_x_ml-unset _x_text-white _x_text-left">
          ყველაზე დიდი ონლაინ მაღაზია
        </p>
      </div>

      <!-- col /. -->
    <div class="_x_col-span-12 md:_x_col-span-7 md:_x_pt-0 _x_flex _x_justify-between md:_x_justify-around _x_flex-col md:_x_flex-row md:_x_pr-10 lg:_x_pr-15 xl:_x_pr-18">
      <div *ngFor="let group of staticPagesGroup$ | async; let i = index" class="_x_mb-7 md:_x_mb-0">
        <h3 (click)="toggleMenu(i)" [ngClass]="menuCollapse[i] ? '_x_text-white' : '_x_text-white-700'"
          class="_x_mb-0 md:_x_mb-10 _x_flex _x_font-bold sm:_x_text-white _x_text-3 _x_pt-7 md:_x_pt-0 _x_border-t-1 _x_border-white-100 _x_border-solid md:_x_border-0">
          {{group.name}}
          <div [ngClass]="menuCollapse[i] ? '_x_rotate-180' : ''"
            class="_x_ml-auto _x_transition _x_transform _x_duration-300 _x_flex md:_x_hidden">
            <i class="_x_icon _x_text-6 _x_icon-addow-down"></i>
          </div>
        </h3>
          <ul class="_x_mt-7" [ngClass]="menuCollapse[i] ? '' : '_x_hidden md:_x_block'">
            <li *ngFor="let staticPages of group.staticPages"
            class="_x_mb-7 md:_x_block _x_pl-0 _x_pr-0">
              <a
                navigateToCorrectUrl
                localUrlPrefix="static/"
                url="{{staticPages.url}}"
                class="_x_font-medium _x_text-3 _x_text-white-700 _x_ease-in-out _x_duration-300 _x_text-left hover:_x_text-white-700"
              >
                {{staticPages.name}}
              </a>
            </li>
          </ul>
      </div>
    </div>
      <!-- col /. -->
      <div class="_x_col-span-12 md:_x_col-span-2 md:_x_col-start-11 xl:_x_-ml-10">
        <div class="_x_">
          <h3 (click)="toggleMenu(3)" [ngClass]="menuCollapse[3] ? '_x_text-white' : '_x_text-white-700'"
            class="_x_flex _x_font-bold sm:_x_text-white _x_text-3 _x_p-7 _x_pl-0 _x_pr-0  md:_x_p-0 _x_border-t-1 _x_border-white-100 _x_border-solid md:_x_border-0">
            დაგვიკავშირდით
            <div [ngClass]="menuCollapse[3] ? '_x_rotate-180' : ''"
              class="_x_ml-auto _x_transition _x_transform _x_duration-300 _x_flex md:_x_hidden">
              <i class="_x_icon _x_text-6 _x_icon-addow-down"></i>
            </div>
          </h3>
          <ul class="_x_mb-7 sm:_x_mt-10 _x_flex-col sm:_x_px-unset"
            [ngClass]="menuCollapse[3] ? '' : '_x_hidden md:_x_flex'">
            <div
              class="_x_flex _x_items-center _x_justify-start sm:_x_justify-between md:_x_flex-col md:_x_items-start xl:_x_flex-row">
              <li class="_x_mr-10 sm:_x_mr-unset">
                <a href="tel:0322333111" class="_x_flex _x_items-center">
                  <i
                    class="_x_flex _x_justify-center _x_align-center _x_icon _x_text-7 _x_w-12 _x_min-w-12 _x_h-12 _x_min-h-12 _x_icon-call _x_text-white-700 _x_mr-3"></i>
                  <div>
                    <span class="_x_text-3 _x_font-bold _x_text-white-700 _x_ease-in-out _x_duration-300 _x_mr-3 _x_pb-px">
                      032 2 333 111 </span>
                  </div>
                </a>
              </li>
              <li class="_x_pr-1">
                <a href="mailto:info@extra.ge" class="_x_flex _x_items-center">
                  <i
                    class="_x_flex _x_justify-center _x_align-center _x_icon _x_text-7 _x_w-12 _x_min-w-12 _x_h-12 _x_min-h-12 _x_icon-mail _x_text-white-700 _x_mr-3"></i>
                  <div>
                    <span class="_x_text-3 _x_font-bold _x_text-white-700 _x_transition _x_duration-300 _x_flex _x_pb-px">
                      info@extra.ge</span>
                  </div>
                </a>
              </li>
            </div>
            <div class="_x_flex _x_items-center _x_flex-wrap _x_gap-5 xl:_x_mt-10">
               <li class="_x_mr-10 sm:_x_mr-unset xl:_x_pb-5">
                  <div>
                    <span
                      class="_x_font-medium _x_text-3 _x_text-white-700 _x_text-left">
                      სს „ექსტრა არეა“ ს/კ 402129763 თბილისი, პეკინის გამზირი, N 41
                    </span>
                  </div>
                </li>
              <li class="_x_group">
                <a href="https://www.facebook.com/Extramarketplace/" target="_blank"
                  class="_x_flex _x_items-center _x_justify-center group-hover:_x_border-white _x_w-20 _x_h-20 _x_rounded-8 _x_border-1 _x_border-white-700">
                  <i class="_x_icon _x_icon-fb _x_text-6 _x_text-white-700 group-hover:_x_text-white _x_mt-px"></i>
                </a>
              </li>
              <li class="_x_group">
                <a href="https://www.instagram.com/extra__ge/" target="_blank"
                  class="_x_flex _x_items-center _x_justify-center group-hover:_x_border-white _x_w-20 _x_h-20 _x_rounded-8 _x_border-1 _x_border-white-700">
                  <i class="_x_icon _x_icon-insta _x_text-6 _x_text-white-700 group-hover:_x_text-white _x_mt-px"></i>
                </a>
              </li>
              <li class="_x_group">
                <a href="https://www.youtube.com/channel/UCt15iQDgO_LykoFZjynI_vg" target="_blank"
                  class="_x_flex _x_items-center _x_justify-center group-hover:_x_border-white _x_w-20 _x_h-20 _x_rounded-8 _x_border-1 _x_border-white-700">
                  <i class="_x_icon _x_icon-yt _x_text-6 _x_text-white-700 group-hover:_x_text-white _x_mt-px"></i>
                </a>
              </li>
              <li class="_x_group">
                <a href="https://www.linkedin.com/company/extra-ge/mycompany/" target="_blank"
                  class="_x_flex _x_items-center _x_justify-center group-hover:_x_border-white _x_w-20 _x_h-20 _x_rounded-8 _x_border-1 _x_border-white-700">
                  <i class="_x_icon _x_icon-linkedin _x_text-6 _x_text-white-700 group-hover:_x_text-white _x_mt-px"></i>
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <!-- col /. -->
      <div class="_x_col-span-12 md:_x_col-span-2 _x_flex sm:_x_hidden _x_w-full md:_x_mx-0 _x_pt-10 _x_justify-center md:_x_justify-start _x_items-center _x_border-t-1 _x_border-white-100 _x_border-solid md:_x_border-0">
        <a class="_x_flex _x_items-center _x_min-w-56" href="https://bit.ly/appextraios" target="_blank">
          <i class="_x_icon _x_text-6 _x_mr-5 _x_icon-apple _x_text-white _x_transition _x_duration-300"></i>
          <div class="_x_block">
            <span class="_x_flex _x_items-center _x_font-bold _x_text-1 _x_text-white-700">Available on
              the</span>
            <span class="_x_flex _x_items-center _x_font-bold _x_text-3 _x_text-white-700">Appstore</span>
          </div>
        </a>
        <div class="_x_border-1 _x_border-white-700 _x_border-t-0 _x_border-b-0 _x_border-r-0 _x_mx-17 _x_h-10"></div>
        <a class="_x_flex _x_items-center _x_min-w-56" href="https://bit.ly/appextraandroid" target="_blank">
          <i class="_x_icon _x_text-6 _x_mr-5 _x_icon-google-store _x_text-white _x_transition _x_duration-300"></i>
          <div class="_x_block">
            <span class="_x_flex _x_items-center _x_font-bold _x_text-1 _x_text-white-700">Get it on</span>
            <span class="_x_flex _x_items-center _x_font-bold _x_text-3 _x_text-white-700">Google Play</span>
          </div>
        </a>
      </div>
      <!-- col /. -->
    </div>
    <!-- grid container /. -->
  </div>


  <!-- footer wrap /. -->
  <div class="_x_flex _x_items-center _x_min-h-33 sm:_x_min-h-39 _x_bg-white-100">
    <div class="_x_max-w-780 _x_px-8 _x_m-auto _x_flex _x_items-center _x_justify-center sm:_x_justify-between _x_w-full">
      <div class="sm:_x_w-fit _x_flex _x_justify-between _x_flex-col sm:_x_flex-initial">
        <span
          class="_x_font-medium _x_text-3 _x_text-white _x_text-center sm:_x_text-left _x_justify-center sm:_x_justify-start sm:_x_mb-0">©
          {{meta.year}} Extra.ge ყველა უფლება დაცულია</span>
      </div>
      <div class="_x_hidden sm:_x_flex _x_justify-center md:_x_justify-start _x_items-center">
        <a class="_x_flex _x_items-center _x_min-w-56" href="https://bit.ly/appextraios" target="_blank">
          <i class="_x_icon _x_text-6 _x_mr-5 _x_icon-apple _x_text-white _x_transition _x_duration-300"></i>
          <div class="_x_block">
            <span class="_x_flex _x_items-center _x_font-bold _x_text-1  _x_text-white-700">Available on
              the</span>
            <span class="_x_flex _x_items-center _x_font-bold _x_text-3 _x_text-white-700">Appstore</span>
          </div>
        </a>
        <div
          class="_x_border-1 _x_border-white _x_opacity-10	 _x_border-t-0 _x_border-b-0 _x_border-r-0 _x_mx-6 _x_h-10">
        </div>
        <a class="_x_flex _x_items-center _x_min-w-56" href="https://bit.ly/appextraandroid" target="_blank">
          <i class="_x_icon _x_text-6 _x_mr-5 _x_icon-google-store _x_text-white _x_transition _x_duration-300"></i>
          <div class="_x_block">
            <span class="_x_flex _x_items-center _x_font-bold _x_text-1  _x_text-white-700">Get it on</span>
            <span class="_x_flex _x_items-center _x_font-bold _x_text-3 _x_text-white-700">Google Play</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</footer>
