import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { setActiveProduct } from 'projects/web/src/app/store/actions/product.actions';
import { ProductModel } from 'projects/web/src/app/store/models/product.model';
import { selectActiveProduct } from 'projects/web/src/app/store/reducers/product.reducer';
import { Observable } from 'rxjs';

@Injectable()
export class DetailedPageGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<ProductModel>
  ) {}
  product$: Observable<ProductModel> = this.store.pipe(select(selectActiveProduct));

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const getProductId = route.paramMap.get('id');
    const getProductSlug = route.paramMap.get('slug');
    this.store.dispatch(setActiveProduct({ id: getProductId, slug: getProductSlug }));
    this.product$
      .subscribe(product => {
        if (product) {
          if (product.productOriginalSlug !== getProductSlug) {
            this.router.navigate(['/product', product.productOriginalSlug, getProductId]);
          }
        }
      })
      .unsubscribe();

    return true;
  }
}
