import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  UrlTree,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { GlobalDataHttp } from 'lib-core';
import { Observable, catchError, map, of } from 'rxjs';
import { UserTypeService } from '../../services/user-type.service';

@Injectable()
export class ImpersonateUserGuard implements CanLoad, CanActivate {
  constructor(
    private globalDataHttp: GlobalDataHttp,
    private router: Router,
    private userTypeService: UserTypeService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    if (this.userTypeService.isUserLegalEntity) {
      return of(true);
    }
    return this.globalDataHttp.isImpersonatedUser().pipe(
      map(res => this.onSuccess(res)),
      catchError(() => this.onError())
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (this.userTypeService.isUserLegalEntity) {
      return of(true);
    }
    return this.globalDataHttp.isImpersonatedUser().pipe(
      map(res => this.onSuccess(res)),
      catchError(() => this.onError())
    );
  }

  private onSuccess(res: boolean): boolean {
    if (res) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  private onError(): Observable<boolean> {
    this.router.navigate(['/']);
    return of(false);
  }
}
