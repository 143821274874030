import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { sidebarOpen } from '../../store/actions/layout.actions';
import * as fromAuth from '../../store/models/auth.model';
import * as fromLayout from '../../store/models/layout.model';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private store: Store<fromAuth.AuthModel | fromLayout.SidebarModel>,
    private _oauthService: OAuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  private get loggedIn() {
    return isPlatformServer(this.platformId) || localStorage.getItem('access_token');
  }

  private check(): boolean {
    if (this.loggedIn) {
      this.store.dispatch(sidebarOpen({ burgerStatus: false }));
      return true;
    }
    this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    this.store.dispatch(sidebarOpen({ burgerStatus: true, activeComponent: 'sign-in' }));
    return false;
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }
}
