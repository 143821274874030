import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStorageService, ProfileHttp } from 'lib-core';
import { DevAuthService } from 'projects/lib-core/src/lib/services/dev-auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DevAppGuard implements CanActivate {
  constructor(
    private profileHttp: ProfileHttp,
    private localStorageService: LocalStorageService,
    private router: Router,
    private devAuthService: DevAuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (environment.production || !isPlatformBrowser(this.platformId)) {
      return true;
    }

    let devUser: { username: string; password: string } = this.localStorageService.getValue('dev-user');

    if (!devUser?.username || !devUser?.password) {
      this.router.navigate(['/dev-auth']);
      return false;
    }

    if (this.devAuthService.isDevUserAuthorized) {
      return true;
    }

    return this.profileHttp.userDevAuthorization(devUser.username, devUser.password).pipe(
      switchMap(() => {
        this.devAuthService.isDevUserAuthorized = true;
        return of(true);
      }),
      catchError(() => {
        this.devAuthService.isDevUserAuthorized = false;
        this.router.navigate(['/dev-auth']);
        return of(false);
      })
    );
  }
}
