import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  NgModule,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Type,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputWothoutNumberDirectiveModule } from '../../../../directives/input-wothout-number.directive';
import { PromoCodeErrorTypes } from '../../../../models/enums/checkout.enum';
import { Unsubscriber } from 'lib-core';

@Component({
  selector: 'app-new-input',
  templateUrl: './new-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewInputComponent),
      multi: true
    }
  ]
})
export class NewInputComponent extends Unsubscriber implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() placeholder = 'ჩაწერე';
  @Input('value') _value: any;
  @Input() withoutNumber: boolean = false;
  PromoCodeErrorTypes = PromoCodeErrorTypes;
  @Input()
  set disableKey(value: any) {
    this._input.nativeElement.addEventListener('keydown', event => {
      if (event.isComposing || event.keyCode === value) {
        event.preventDefault();
      }
    });
  }

  @Input()
  set focus(value: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.timeoutIds.push(setTimeout(() => this._input.nativeElement.focus()));
  }

  @Input() classList = {
    input: '',
    label: ''
  };

  @Input() require: boolean;
  @Input() isCustomBgColor: boolean;
  @Input() isInvalid: boolean;
  @Input() promoCodeError: number;

  @Input()
  set disabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  @ViewChild('input', { static: true }) _input: ElementRef;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
  }

  inputUIType = 'password';
  isFocused: boolean = false;
  @Input() inputType = 'text';

  @Output() ngModelChange = new EventEmitter<any>();

  @Output() focusStatus = new EventEmitter<any>();

  @Input() maxLength: number = 64;
  @Input() maskk: string;
  @Input() separatorLimit;
  @Input()
  autocomplete = 'new-recover-password';

  isDisabled: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private inj: Injector,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super();
  }

  ngAfterViewInit(): void {
    const ngControl = this.inj.get<NgControl>(NgControl as unknown as Type<NgControl>, null);
    if (ngControl) {
      ngControl.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }

  writeValue(value: any): void {
    this.inputUIType = this.inputType;
    this.value = value;
    this.cdr.detectChanges();
  }

  onModelChange() {
    this.ngModelChange.emit(this.value);
  }

  toggleInputType() {
    this.inputUIType = this.inputUIType === 'password' ? 'text' : 'password';
  }

  onChange = (val: any) => {};

  onTouched = () => {};

  toggleFocus(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._input.nativeElement.removeEventListener('keydown', event => {});
  }
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputWothoutNumberDirectiveModule,
    NgxMaskModule.forRoot(options)
  ],
  declarations: [NewInputComponent],
  providers: [],
  exports: [NewInputComponent]
})
export class NewInputModule {}
