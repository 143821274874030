import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MerchantsHttp } from 'lib-core';
import { Observable } from 'rxjs';

@Injectable()
export class SellerPageGuard implements CanActivate {
  constructor(
    private router: Router,
    private merchantsService: MerchantsHttp
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const getsellerId = route.paramMap.get('sellerId');
    const getSellerName = route.paramMap.get('sellerName');
    const getCategorySlug = route.paramMap.get('categorySlug');
    const getCategoryId = route.paramMap.get('categoryId');

    this.merchantsService
      .getLegalMerchantDetailsByUserId(getsellerId)
      .subscribe(response => {
        if (response) {
          const { slug, merchantId } = response;

          if (slug && slug !== getSellerName && getCategorySlug && getCategoryId) {
            this.router.navigate(['/seller', slug, merchantId, getCategorySlug, getCategoryId]);
          } else if (slug && slug !== getSellerName) {
            this.router.navigate(['/seller', slug, merchantId]);
          }
        }
      })
      .unsubscribe();
    return true;
  }
}
