<div
  class="_s_label-a-center _x_fixed _x_left-0 _x_top-0 _x_z-10 _x_mb-auto _x_flex _x_h-100vh _x_w-full _x_items-center _x_justify-center _x_bg-dark-900">
  <div
    class="_x_relative _x_mx-8 _x_flex _x_w-294 _x_max-w-294 _x_flex-col _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-8 _x_bg-white sm:_x_h-294 sm:_x_max-h-294"
    clickOutside
    (onClickOutside)="this.closeSuccessPopup.emit(); this.overflowHandler(false)">
    <button
      class="_x_absolute _x_right-8 _x_top-8 _x_flex _x_items-center _x_justify-center"
      (click)="closeSuccessPopup.emit()">
      <i
        class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_max-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-4 _x_text-light-gray"></i>
    </button>
    <div
      class="_x_flex _x_w-full _x_flex-col _x_items-center _x_justify-center _x_px-10 _x_pb-10 _x_pt-30 sm:_x_pb-25 sm:_x_pt-18 md:_x_px-unset">
      <a class="_x_mb-18 _x_w-full _x_px-12 sm:_x_mb-25 sm:_x_px-30">
        <img class="_x_w-full" src="../assets/img/svg/buy-success.svg" alt="" />
      </a>
      <p class="_x_mb-6 _x_font-bold _x_text-4 _x_text-black sm:_x_mb-7 sm:_x_text-6">შენი ელ-ფოსტა დავიმახსოვრეთ.</p>
      <p class="_x_mb-10 _x_font-medium _x_text-2 _x_text-black sm:_x_text-4">
        დღეიდან ყველა სიახლეს აუცილებლად გაგაგებინებთ
      </p>
      <button
        (click)="closeSuccessPopup.emit()"
        class="_x_col-span-12 _x_h-24 _x_w-full _x_max-w-150 _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple _x_text-white _x_transition _x_duration-300 lg:_x_flex">
        <span class="_x_font-bold _x_text-2">დახურვა</span>
      </button>
    </div>
  </div>
</div>
