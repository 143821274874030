<app-error-modal *ngIf="showErrorModal | async"></app-error-modal>
<app-notification-popup-main></app-notification-popup-main>
<div
  class="_x_min-h-[270px] sm:_x_min-h-[224px] md:_x_min-h-[144px]"
  [ngClass]="{
    '!_x_min-h-[222px] md:_x_min-h-[124px]': !(selectRibbonActiveState$ | async),
    'md:_x_min-h-[34]': !(userStatus$ | async) && isBasket,
    '!_x_min-h-[347px] sm:!_x_min-h-[300px]':
      (smartBannerService.openSmartBanner$ | async) && !smartBannerService.isFromSafari
  }">
  <app-header></app-header>
</div>
<app-toaster></app-toaster>
<app-global-modal *ngIf="globalModalService.component$ | async"></app-global-modal>

<div id="router-holder" class="_x_mt-8 _x_min-h-[100vh]" [ngClass]="{ 'md:!_x_mt-0': isDifferentSpacePage }">
  <router-outlet></router-outlet>
</div>

<app-address-listing *ngIf="isAddressListingModalOpen"></app-address-listing>
<app-address-form *ngIf="isAddressFormModalOpen"></app-address-form>
<app-order-debt
  *ngIf="orderWithDebt && orderDebtService.isOrderDebtModalOpen"
  [orderWithDebt]="orderWithDebt"></app-order-debt>
<app-debt-payment-modal *ngIf="orderDebtService.isPaymentModalOpen"></app-debt-payment-modal>
<app-confirmation-dialog *ngIf="showConfirmationDialog"></app-confirmation-dialog>
<app-sidebar *ngIf="(layoutStatus$ | async).burgerStatus"></app-sidebar>
<app-footer></app-footer>
<app-popup-overlay></app-popup-overlay>
<app-backdrop></app-backdrop>

<app-cookie-banner *ngIf="showCookieBannerModal | async"></app-cookie-banner>
<div class="fb-customerchat" attribution="setup_tool" page_id="211265162810563" greeting_dialog_display="icon"></div>
