import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SidebarComponent } from './sidebar.component';
import { RecoverPasswordService } from './sign/recover-password/recover-password.service';
import { DynamicHostDirectiveModule } from '../../shared/directives/dynamic-host.directive';

export const SIDEBAR_ROUTES: Routes = [];

export const COMPONENTS = [SidebarComponent];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [CommonModule, RouterModule, NgxMaskModule.forRoot(options), DynamicHostDirectiveModule],
  declarations: [...COMPONENTS],
  providers: [RecoverPasswordService],
  exports: [SidebarComponent]
})
export class SidebarModule {}
