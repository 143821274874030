import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { GlobalDataHttp, GoogleMapOptionsService, Unsubscriber } from 'lib-core';
import { allcitys } from 'projects/lib-core/src/lib/models/map.model';
import { Observable, takeUntil } from 'rxjs';
import { UNAUTHORIZED_USER_ADDRESS } from '../../../constants/express.constants';
import { AddressService } from '../../../services/address.service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';
import { ExpressService } from '../../../services/express.service';
import { IsMobileService } from '../../../services/is-mobile.service';
import { AddShippingAddressAttempt, EditShippingAddressAttempt } from '../../../store/actions/addresses.actions';
import { AddressesStoreModel } from '../../../store/models/addresses.model';
import { selectAddressesAmount } from '../../../store/reducers/addresses.reducer';
import { getUserStatus } from '../../../store/reducers/auth.reducer';
import { DynamicSelectModule } from '../HOC/forms/dynamic-select/dynamic-select.module';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapsModule } from '@angular/google-maps';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, DynamicSelectModule, TranslateModule, GoogleMapsModule],
  providers: [GoogleMapOptionsService],
  standalone: true
})
export class AddressFormComponent extends Unsubscriber implements OnInit, AfterViewInit {
  @ViewChild('autocompleInput') autocompleInput: ElementRef;

  @ViewChild('autocompleteMobile') autocompleteMobile: ElementRef;

  @ViewChild('addressFormElement', { static: false }) addressFormElement!: ElementRef;

  public isMobile: boolean;
  isDropdownOpen = false;
  public mapOptions: google.maps.MapOptions;
  public isAddressInputFocused = false;
  markerOptions = {
    icon: {
      url: 'assets/img/svg/Pin.svg',
      size: new google.maps.Size(29, 45)
    }
  };
  public openMapMobile = false;
  public openMapMobileIsTriggered = false;
  public mapCenter: google.maps.LatLngLiteral = {
    lat: 41.730141,
    lng: 44.7685945
  };
  public mapCenterMobile: google.maps.LatLngLiteral = {
    lat: 41.730141,
    lng: 44.7685945
  };
  public selectedPinMobile: google.maps.LatLng | null = null;
  public selectedPin: google.maps.LatLng | null = null;
  public addressForm: FormGroup;
  public addressInputIsInvalid = true;
  public disabledDefaultAddressCheckbox: boolean = true;
  public disableSubmit: boolean = false;
  private selectAddressesAmount$ = this.store.select(selectAddressesAmount);
  private savePlaceInfoForMobileSetting: google.maps.places.PlaceResult = null;
  // this variable is necessary, because dynamic select emits valuechange on dropdown open -_-
  private handleDynamicSelectMultipleChange: string = null;
  private isMapOptionsSetBasedOnLocationId = false;
  private userStatus$: Observable<any> = this.store.pipe(select(getUserStatus));
  private userStatus: boolean;

  constructor(
    private globalDataHttp: GlobalDataHttp,
    private addressService: AddressService,
    private store: Store<AddressesStoreModel>,
    private cdr: ChangeDetectorRef,
    private confirmationDialogService: ConfirmationDialogService,
    private isMobileService: IsMobileService,
    private googleMapOptionsService: GoogleMapOptionsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private expresService: ExpressService
  ) {
    super();
  }

  get isEditMode(): boolean {
    return Boolean(this.addressService.editAddressData);
  }

  get isLocationIdSet(): boolean {
    return Boolean(this.addressForm?.get('locationId')?.value);
  }

  get isLatLongSet(): boolean {
    return Boolean(this.addressForm.get('latitude').value && this.addressForm.get('longitude').value);
  }

  get isFromExpress(): boolean {
    return this.addressService.isFromExpress;
  }

  ngOnInit(): void {
    this.setMapOptionsBasedOnLocationId();

    this.isMobileService.isMobileDevice.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      this.isMobile = res;
      this.mapOptions.controlSize = res ? 30 : 40;
      this.cdr.markForCheck();
    });

    this.addressForm = this.addressService.createAddressForm();
    // if it's edit mode
    this.ifItsEditModeSetValuesToForm();

    // let me thing about it
    // this.listenToAddressChanges()

    this.selectAddressesAmount$.pipe(takeUntil(this.destroy$)).subscribe((addressesAmount: number) => {
      if (addressesAmount === 0 || (addressesAmount === 1 && this.isEditMode)) {
        this.addressForm.get('isDefault').setValue(true);
        this.addressForm.get('isDefault').disable();
        this.disabledDefaultAddressCheckbox = true;
      } else {
        this.addressForm.get('isDefault').enable();
        this.disabledDefaultAddressCheckbox = false;
      }
    });

    this.userStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.userStatus = status;
    });
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
    if (this.addressFormElement) {
      const addressForm = this.addressFormElement.nativeElement;
      const contentHeight = addressForm.offsetHeight + 25;
      addressForm.style.setProperty('--content-height', contentHeight + 'px');
    }
  }

  public setAddressInputIvalid(): void {
    this.addressInputIsInvalid = true;
  }

  public onAddressInputFocus(): void {
    this.isAddressInputFocused = true;
  }

  public onAddressInputFocusOut(): void {
    this.isAddressInputFocused = false;
  }

  public setValueToFormElement(key: string, value: string | number): void {
    this.addressForm.get(key).setValue(value);

    if (key === 'address') {
      this.addressInputIsInvalid = false;
      this.addressForm.get('address').markAsDirty();
    }
  }

  public closeAddressFormModal(): void {
    if (this.openMapMobile) {
      this.closeMobileOpenMap();
    } else {
      this.addressService.updateAddressListingWithNewValue = false;
      this.addressService.closeAddressFormModal();
    }
  }

  // copied for dynamic select
  public getLocationById = (id): Observable<any> => {
    return this.globalDataHttp.getLocationById(id);
  };

  // copied for dynamic select
  public getLocationTODO = (state: any): Observable<any> => {
    return this.globalDataHttp.getLocation(state.pageIndex, state.pageSize, state.searchValue);
  };

  public setLocationBasedOnOurCities(value: { caption: string; id: number } | null): void {
    this.autocompleInput?.nativeElement?.blur();

    if (value?.id && value?.caption) {
      this.setValueToFormElement('locationId', value.id);
      this.setValueToFormElement('location', value.caption);
      this.addressForm.get('locationId').markAsTouched();
      // on location change, pin must be set on map, but if there is address already set, then don't touch it
      if (this.handleDynamicSelectMultipleChange !== value.caption && !this.addressForm.get('address')?.value) {
        this.findAndSetValueOnMapBasedOnAddressString(value.caption, false);
      }
      this.handleDynamicSelectMultipleChange = value.caption;

      if (!this.isMapOptionsSetBasedOnLocationId) {
        this.setMapOptionsBasedOnLocationId();
        this.isMapOptionsSetBasedOnLocationId = true;
      }
    } else {
      this.handleDynamicSelectMultipleChange = null;
    }
  }

  public onMapClick(event: google.maps.MapMouseEvent): void {
    if (!this.isLocationIdSet) {
      return;
    }
    this.disableSubmit = true;
    this.selectedPin = event.latLng;
    this.setLatLongValueToForm();

    this.findAndSetAutocompletetLocationBasedOnLatLng(event.latLng);
  }

  public submitAddressData(): void {
    // need check if its edit, then call edit function
    if (this.addressForm.valid && this.googleMapOptionsService.isCorrectPin(this.addressForm.value.address, allcitys)) {
      if (this.isEditMode) {
        this.editAddress();
      } else {
        if (this.userStatus) {
          this.store.dispatch(AddShippingAddressAttempt({ shippingAddress: this.addressForm.value }));
        } else {
          this.saveAddressData();
        }
      }
    }
  }

  public openMapForMobile(): void {
    this.openMapMobileIsTriggered = true;
    this.openMapMobile = true;
    this.setMobileMapValuesBeforeLoad();

    setTimeout(() => {
      this.getMobileSettingAutocompleteAndMap();
      this.autocompleteMobile.nativeElement.focus();
      this.cdr.markForCheck();
    }, 1000);
  }

  public changeAddressChangesForMobile(): void {
    if (this.savePlaceInfoForMobileSetting) {
      this.updatePinWithAutocompleteValue(this.savePlaceInfoForMobileSetting);
      this.setValueToFormElement('address', this.savePlaceInfoForMobileSetting.formatted_address);
    }

    this.closeMobileOpenMap();
  }

  public onMobileMapClick(event: google.maps.MapMouseEvent): void {
    if (!this.isLocationIdSet) {
      return;
    }
    this.disableSubmit = true;
    this.selectedPinMobile = event.latLng;
    this.findAndSetMobileAutocompletetLocationBasedOnLatLng(event.latLng);
  }

  public closeMobileOpenMap(): void {
    this.openMapMobile = false;
    this.selectedPinMobile = null;
    this.mapCenterMobile = null;
  }

  private setMapOptionsBasedOnLocationId(): void {
    this.mapOptions = {
      ...this.mapOptions,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT
      },
      clickableIcons: false,
      draggable: this.isLocationIdSet, // Disable map dragging
      zoomControl: this.isLocationIdSet, // Disable zoom control
      scrollwheel: this.isLocationIdSet,
      disableDoubleClickZoom: !this.isLocationIdSet, // Disable double-click zoom
      keyboardShortcuts: this.isLocationIdSet,
      fullscreenControl: this.isLocationIdSet,
      gestureHandling: this.isLocationIdSet ? 'auto' : 'none',
      streetViewControl: false,
      mapTypeControl: false,
      styles: this.googleMapOptionsService.makeOnlyStreenVisible(),
      restriction: {
        latLngBounds: this.googleMapOptionsService.getLatLngBounds(), // რუკის საზღვრების დაწესება
        strictBounds: true // პინის შესზღუდვა საზღვრებს გარეთ
      },
      minZoom: 5,
      maxZoom: 19
    };
  }

  private ifItsEditModeSetValuesToForm(): void {
    if (this.isEditMode) {
      this.addressForm.patchValue(this.addressService.editAddressData);
      this.addressInputIsInvalid = false;

      const convertLatLng = new google.maps.LatLng(
        this.addressService.editAddressData.latitude,
        this.addressService.editAddressData.longitude
      );
      this.updateAndCenterPin(convertLatLng);
    }
  }

  private findAndSetValueOnMapBasedOnAddressString(address: string, updateAutocompleteValue = true): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.disableSubmit = true;
        if (this.isCorrectPin(results[0].formatted_address)) {
          this.autocompleInput.nativeElement.value = updateAutocompleteValue ? results[0].formatted_address : null;
          this.setValueToFormElement('address', this.autocompleInput.nativeElement.value);
          this.updatePinWithAutocompleteValue(results[0]);
          this.disableSubmit = false;
          this.cdr.markForCheck();
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  private setLatLongValueToForm(): void {
    this.setValueToFormElement('latitude', this.selectedPin.lat());
    this.setValueToFormElement('longitude', this.selectedPin.lng());
  }

  private findAndSetAutocompletetLocationBasedOnLatLng(latLng: google.maps.LatLng): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.disableSubmit = true;
        const address: string = results[0].formatted_address;
        if (this.isCorrectPin(address)) {
          this.autocompleInput.nativeElement.value = address;
          this.setValueToFormElement('address', this.autocompleInput.nativeElement.value);
          this.disableSubmit = false;
          this.cdr.markForCheck();
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.autocompleInput.nativeElement, {
      componentRestrictions: { country: ['ge', 'am'] }
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.disableSubmit = true;
      const place = autocomplete.getPlace();
      if (this.isCorrectPin(place?.formatted_address)) {
        this.setValueToFormElement('address', place?.formatted_address);
        this.updatePinWithAutocompleteValue(place);
        this.disableSubmit = false;
        this.cdr.markForCheck();
      }
    });
  }

  private updatePinWithAutocompleteValue(place) {
    this.selectedPin = place?.geometry?.location;
    this.setLatLongValueToForm();
    this.centerGoogleMapWithSelectedPin();
    this.cdr.markForCheck();
  }

  private centerGoogleMapWithSelectedPin(): void {
    this.mapCenter = {
      lat: this.selectedPin.lat(),
      lng: this.selectedPin.lng()
    };
  }

  private updateAndCenterPin(location: google.maps.LatLng): void {
    this.selectedPin = location;
    this.centerGoogleMapWithSelectedPin();
    this.cdr.markForCheck();
  }

  private editAddress(): void {
    this.confirmationDialogService.openConfirmationModal({
      title: 'ცვლილებების შენახვა',
      text: 'გსურთ შეინახოთ ცვლილება?',
      confirmAction: () => {
        if (this.userStatus) {
          this.store.dispatch(EditShippingAddressAttempt(this.addressForm.value));
        } else {
          this.saveAddressData();
        }
      }
    });
  }

  private saveAddressData(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(UNAUTHORIZED_USER_ADDRESS, JSON.stringify(this.addressForm.value));
      this.expresService.updateDarkStoreData(true, this.addressForm.value);
      this.addressService.closeAddressFormModal();
      this.confirmationDialogService.closeConfirmationModal();
      this.cdr.markForCheck();
    }
  }

  private setMobileMapValuesBeforeLoad(): void {
    this.selectedPinMobile = JSON.parse(JSON.stringify(this.selectedPin));
    this.mapCenterMobile = JSON.parse(JSON.stringify(this.mapCenter));
    setTimeout(() => {
      this.autocompleteMobile.nativeElement.value = this.addressForm.get('address').value;
      this.cdr.markForCheck();
    });
  }

  private getMobileSettingAutocompleteAndMap() {
    const autocomplete = new google.maps.places.Autocomplete(this.autocompleteMobile?.nativeElement, {
      componentRestrictions: { country: ['ge', 'am'] }
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.disableSubmit = true;
      const place = autocomplete.getPlace();
      if (this.isCorrectPin(autocomplete.getPlace()?.formatted_address)) {
        this.savePlaceInfoForMobileSetting = place;
        this.updatePinWithAutocompleteValueMobile(this.savePlaceInfoForMobileSetting);
        this.addressInputIsInvalid = false;
        this.disableSubmit = false;
        this.cdr.markForCheck();
      }
    });
  }

  private findAndSetMobileAutocompletetLocationBasedOnLatLng(latLng: google.maps.LatLng): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.disableSubmit = true;
        const address: string = results[0].formatted_address;
        if (this.isCorrectPin(address)) {
          this.savePlaceInfoForMobileSetting = results[0];
          this.autocompleteMobile.nativeElement.value = address;
          this.addressInputIsInvalid = false;
          this.disableSubmit = false;
          this.cdr.markForCheck();
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  private updatePinWithAutocompleteValueMobile(place) {
    this.selectedPinMobile = place?.geometry?.location;
    this.centerGoogleMapWithSelectedPinMobile();
    this.cdr.markForCheck();
  }

  private centerGoogleMapWithSelectedPinMobile(): void {
    this.mapCenterMobile = {
      lat: this.selectedPinMobile.lat(),
      lng: this.selectedPinMobile.lng()
    };
  }

  private isCorrectPin(address: string | null): boolean {
    address = address ? address : '';
    if (!this.googleMapOptionsService.isCorrectPin(address, allcitys)) {
      if (this.autocompleteMobile) {
        this.autocompleteMobile.nativeElement.value = '';
      }
      if (this.autocompleInput) {
        this.autocompleInput.nativeElement.value = '';
      }
      this.selectedPinMobile = null;
      this.selectedPin = null;
      this.confirmationDialogService.closeConfirmationModal();
      alert('სამწუხაროდ, მითითებულ მისამართზე ჩვენი მომსახურება არ ვრცელდება');
      this.cdr.markForCheck();
      return false;
    }
    return true;
  }
}
