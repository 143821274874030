import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DynamicServiceTokenSubject } from '../shared/tokens/dynamic-service.token';
import { CustomersHttp, LegalEntityService, ProfileHttp } from 'lib-core';
import { AuthConfig, LoginOptions, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { DynamicCustomerServiceToken } from '../shared/tokens/dynamic-customer-service.token';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {
  constructor(
    @Inject(DynamicServiceTokenSubject)
    public tokenSubject: BehaviorSubject<any>,
    @Inject(DynamicCustomerServiceToken)
    public customerService: BehaviorSubject<any>,
    private profileHttp: ProfileHttp,
    private legalEntityService: LegalEntityService,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private customersHttp: CustomersHttp,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  get isUserLegalEntity(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('user-type') === 'legal-entity';
    }
  }

  getUserType(): void {
    if (this.isUserLegalEntity) {
      this.tokenSubject.next(this.legalEntityService);
      this.customerService.next(this.legalEntityService);
    } else {
      this.tokenSubject.next(this.profileHttp);
      this.customerService.next(this.customersHttp);
    }
  }

  changeUserType(type: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const currentType = localStorage.getItem('user-type');
    if (currentType !== type) {
      localStorage.setItem('user-type', type);
    } else {
      return;
    }
    const authConfig: AuthConfig = this.isUserLegalEntity
      ? {
          issuer: environment.juridicalIssuerUrl,
          loginUrl: environment.juridicalIssuerUrl + '/connect/authorize',
          logoutUrl: environment.juridicalIssuerUrl + '/connect/endsession',
          tokenEndpoint: environment.juridicalIssuerUrl + '/connect/token',
          userinfoEndpoint: environment.juridicalIssuerUrl + '/connect/userinfo',
          clientId: 'dev',
          dummyClientSecret: 'secret',
          scope: 'offline_access openid',
          redirectUri: window.location.href,
          requireHttps: false,
          oidc: this.cookieService.get('oidc') == 'true',
          silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html'
        }
      : {
          issuer: environment.issuerUrl,
          loginUrl: environment.issuerUrl + '/connect/authorize',
          logoutUrl: environment.issuerUrl + '/connect/endsession',
          tokenEndpoint: environment.issuerUrl + '/connect/token',
          userinfoEndpoint: environment.issuerUrl + '/connect/userinfo',
          redirectUri: window.location.href,
          clientId: 'dev',
          scope: 'identity offline_access openid email profile phone address',
          dummyClientSecret: 'secret',
          requireHttps: false,
          oidc: this.cookieService.get('oidc') == 'true',
          silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html'
        };

    this.oauthService.configure(authConfig);
    let loginOptions = new LoginOptions();
    loginOptions.disableOAuth2StateCheck = true;
    this.oauthService.setupAutomaticSilentRefresh();
    this.getUserType();
  }

  getTokenSubjectObservable(): Observable<any> {
    return this.tokenSubject.asObservable();
  }

  getCustomerServiceObservable(): Observable<any> {
    return this.customerService.asObservable();
  }
}
