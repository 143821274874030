import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'projects/web/src/environments/environment';

let GTAG_GTM: string;
let GTAG_G: string;
if (environment.develop) {
  GTAG_GTM = 'GTM-MDMJ6MZ';
  GTAG_G = 'G-Q0HEYWJNQH';
} else if (environment.staging) {
  GTAG_GTM = 'GTM-THMGXCGV';
  GTAG_G = 'G-Q0HEYWJNQH';
} else {
  GTAG_GTM = 'GTM-52WQ3TH';
  GTAG_G = 'G-YYVM7L0FYH';
}

@Injectable({
  providedIn: 'root'
})
export class ScriptsService {
  private renderer!: Renderer2;
  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public loadScript() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.loadGoogleTageManagerScript();
    this.loadGlobalSiteTagGtagJs();
    this.loadGoogleTageManagerNoscript();

    if (environment.production) {
      this.loadOmnisendScript();
    }
  }

  public loadBitrixScript() {
    setTimeout(() => {
      const src: string = 'https://crm.area.ge/upload/crm/site_button/loader_1_0eovue.js';
      const script = document.createElement('script');
      script.async = true;
      script.src = `${src}?${Math.floor(Date.now() / 60000)}`;
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode!.insertBefore(script, firstScript);
    }, 0);
  }

  private getScriptElement(
    position: 'body' | 'head',
    src: string,
    async: boolean = false,
    type: string = undefined
  ): HTMLScriptElement {
    const script: HTMLScriptElement = this.renderer.createElement('script');
    if (type) {
      script.type = type;
    }
    if (src) {
      script.src = src;
    }
    if (async) {
      script.async = true;
    }
    this.renderer.appendChild(this.document[position], script);
    return script;
  }

  private loadGoogleTageManagerScript() {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j: any = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTAG_GTM);
    (window as any).gtag = (...args: any[]) => {
      (window as any)['dataLayer'] = (window as any)['dataLayer'] || [];
      (window as any)['dataLayer'].push(args[0]);
    };
    (window as any).gtag('js', new Date());
  }

  private loadGlobalSiteTagGtagJs() {
    const script1: HTMLScriptElement = this.getScriptElement(
      'head',
      'https://www.googletagmanager.com/gtag/js?id=' + GTAG_G,
      true
    );
    (window as any)['dataLayer'] = (window as any)['dataLayer'] || [];
    (window as any).gtag_1 = (...args: any[]) => {
      (window as any)['dataLayer'].push(args);
    };
    (window as any).gtag_1('js', new Date());
    (window as any).gtag_1('config', GTAG_G);
  }

  private loadGoogleTageManagerNoscript() {
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTAG_GTM}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style = 'display:none;visibility:hidden';
    iframe.loading = 'lazy';
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(this.document.body, noscript);
  }

  private loadOmnisendScript(): void {
    window['omnisend'] = window['omnisend'] || [];
    window['omnisend'].push(['accountID', '65cb2a3a73c08017bfb9fc81']);
    window['omnisend'].push(['track', '$pageViewed']);

    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://omnisnippet1.com/inshop/launcher-v2.js';

    this.renderer.appendChild(document.body, script);
  }
}
