import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule } from 'angular-oauth2-oidc';
import { LibCoreModule } from 'lib-core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './core/global-error-handler';
import { AuthGuard } from './core/guards';
import { ErrorInterceptor } from './core/interceptors/http-request.interceptor';
import { CookieBannerModule } from './layout/cookie-banner/cookie-banner.component';
import { PopularBannerModule } from './layout/popular-banner/popular-banner.module';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarModule } from './layout/sidebar';
import { DocumentService } from './services/document.service';
import { ErrorService } from './services/error.service';
import { BackDropComponent } from './shared/components/HOC/backdrop';
import { PopupOverlayComponent } from './shared/components/HOC/popup-overlay';
import { ErrorModalComponent } from './shared/components/error-modal-component/error-modal.component';
import { DropDownDirectiveModule } from './shared/directives/drop.directive';
import { ImageForSearchDirectiveModule } from './shared/directives/imageForSearch.directive';
import { metaReducers, NGRX_STATE, REDUCERS, State } from './store';
import { BannerEffects } from './store/effects/banner.effects';
import { BasketEffects } from './store/effects/basket.effects';
import { CardsEffects } from './store/effects/cards.effects';
import { CategoryEffects } from './store/effects/category.effects';
import { MerchantEffects } from './store/effects/merchant.effects';
import { OrdersEffects } from './store/effects/orders.effects';
import { ProductEffects } from './store/effects/product.effects';
import { RibbonEffects } from './store/effects/ribbon.effects';
import { SearchByImageEffects } from './store/effects/search-by-image.effect';
import { SectionEffects } from './store/effects/section.effects';
import { SidebarFiltersEffects } from './store/effects/sidebar-filters.effects';
import { UserEffects } from './store/effects/user.effects';
import { WishlsitEffects } from './store/effects/wishlist.effects';

import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import localeKa from '@angular/common/locales/ka';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter, take, tap } from 'rxjs';
import { CatalogPageGuard } from './core/guards/catalog-page.guard';
import { DetailedPageGuard } from './core/guards/detailed-page.guard';
import { SellerPageGuard } from './core/guards/seller-page.guard';
import { PagesWraperComponent } from './pages-wraper/pages-wraper.component';
import { ToasterModule } from './shared/components/toaster/toaster.component';
import { DynamicHostDirectiveModule } from './shared/directives/dynamic-host.directive';
import { NavigateToCorrectUrlModule } from './shared/directives/navigateToCorrectUrl.directive';
import { AddressesEffects } from './store/effects/addresses.effects';
import { CampaignsEffects } from './store/effects/campaigns.effects';
import { ComparisonEffects } from './store/effects/comparison.effects';
import { DynamicServiceTokenSubject } from './shared/tokens/dynamic-service.token';
import { DynamicCustomerServiceToken } from './shared/tokens/dynamic-customer-service.token';
import { OrderDebtComponent } from './shared/components/order-debt/order-debt.component';
import { DebtPaymentModalComponent } from './shared/components/debt-payment-modal/debt-payment-modal.component';
import { GlobalModalComponent } from './shared/components/global-modal/global-modal.component';
import { SmartBannerComponent } from './layout/smart-banner/smart-banner.component';
import { ExpressButtonComponent } from './layout/header/express-button/express-button.component';
import { SplitAddressPipe } from './shared/pipe/split-address.pipe';
import { NotificationPopupMainComponent } from './layout/notification-popup/notification-popup-main.component';
import { AddressListingComponent } from './shared/components/address-listing/address-listing.component';
import { AddressFormComponent } from './shared/components/address-form/address-form.component';
import { ConfirmationDialogComponent } from './layout/confirmation-dialog/confirmation-dialog.component';
import { ImpersonateUserGuard } from './core/guards/impersonate-user.guard';

registerLocaleData(localeKa);
const defaultLanguage = 'ka';

export const COMPONENTS = [
  AppComponent,
  HeaderComponent,
  FooterComponent,
  SmartBannerComponent,
  ExpressButtonComponent
];

export const STANDALONE_COMPONENTS = [
  ErrorModalComponent,
  NotificationPopupMainComponent,
  GlobalModalComponent,
  AddressListingComponent,
  AddressFormComponent,
  OrderDebtComponent,
  DebtPaymentModalComponent,
  ConfirmationDialogComponent,
  PopupOverlayComponent,
  BackDropComponent
];
export const IMPORTS = [
  STANDALONE_COMPONENTS,
  NoopAnimationsModule,
  LayoutModule,
  ReactiveFormsModule,
  FormsModule,
  SidebarModule,
  StoreModule.forRoot(REDUCERS, { metaReducers }),
  StoreDevtoolsModule.instrument({}),
  EffectsModule.forRoot([
    UserEffects,
    ProductEffects,
    CategoryEffects,
    BasketEffects,
    CardsEffects,
    OrdersEffects,
    SidebarFiltersEffects,
    BannerEffects,
    SectionEffects,
    MerchantEffects,
    RibbonEffects,
    SearchByImageEffects,
    WishlsitEffects,
    CampaignsEffects,
    ComparisonEffects,
    AddressesEffects
  ]),
  LibCoreModule.forRoot({ env: environment }),
  HttpClientModule,
  DropDownDirectiveModule,
  ImageForSearchDirectiveModule,
  LayoutModule,
  NavigateToCorrectUrlModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  OAuthModule.forRoot({
    resourceServer: {
      allowedUrls: [
        environment.cmsURL,
        environment.apiUrl,
        environment.customersURL,
        environment.basketUrl,
        environment.emailUrl,
        environment.paymentURL,
        environment.basketUrl,
        environment.orderingURL,
        environment.issuerUrl,
        environment.tradeInUrl,
        environment.reportingURL,
        environment.juridicalUrl
      ],
      sendAccessToken: true
    }
  }),
  CookieBannerModule,
  PopularBannerModule,
  ToasterModule
  // FacebookModule.forRoot(),
];

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
];

export const PROVIDERS = [
  SellerPageGuard,
  DetailedPageGuard,
  CatalogPageGuard,
  AuthGuard,
  CookieService,
  DocumentService,
  ErrorService,
  ...interceptors,
  ImpersonateUserGuard,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },
  {
    provide: DynamicServiceTokenSubject,
    useValue: new BehaviorSubject('') // Provide the initial value here (you can set null or any other initial value)
  },
  {
    provide: DynamicCustomerServiceToken,
    useValue: new BehaviorSubject('')
  },
  { provide: LOCALE_ID, useValue: defaultLanguage }
];

@NgModule({
  declarations: [...COMPONENTS, PagesWraperComponent, SplitAddressPipe],
  imports: [
    ...IMPORTS,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    DynamicHostDirectiveModule
    // SwiperModule
  ],
  providers: [...PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly transferState: TransferState,
    private readonly store: Store<State>,
    private router: Router
  ) {
    this.getAccesTokenFromAppRedirect();
    const isBrowser = this.transferState.hasKey<any>(NGRX_STATE);
    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  getAccesTokenFromAppRedirect() {
    this.router.events
      .pipe(
        filter(event => {
          return event instanceof NavigationStart;
        }),
        take(1),
        tap((event: any) => {
          let url = decodeURIComponent(event.url);
          const urlParts = url.split('?usertype=');
          let userType = urlParts[1];
          if (userType) {
            localStorage.setItem('user-type', userType == '1' ? 'legal-entity' : 'physical');
          }
          let token = urlParts[0].split('token=')[1];
          if (token) {
            localStorage.setItem('access_token', token);
          }
        })
      )
      .subscribe({
        error: err => {
          this.router.navigate(['/']);
        }
      });
  }

  onServer() {
    this.transferState.onSerialize(NGRX_STATE, () => {
      let state: any;
      this.store.subscribe((saveState: any) => (state = saveState)).unsubscribe();
      return state;
    });
  }

  onBrowser() {
    const state = this.transferState.get<any>(NGRX_STATE, null);
    this.transferState.remove(NGRX_STATE);
    this.store.dispatch({ type: 'SET_ROOT_STATE', payload: state });
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  let translateLoader = new TranslateHttpLoader(http, 'assets/i18n/', '.json');
  return translateLoader;
}
